<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm" title="상세">
            <template slot="card-button">
              <q-btn-group outline >
                      <c-btn v-if="editable&&!disabled&&isOld&&!apprMode" label="삭제" icon="delete" @btnClicked="deleteAction" />
                      <c-btn 
                  v-if="editable && !disabled" 
                  :isSubmit="isSave"
                  :url="saveUrl"
                  :param="action"
                  :mappingType="mappingType"
                  label="저장" 
                  icon="save"
                  @beforeAction="saveAction"
                  @btnCallback="saveActionCallback" />
                <c-appr-btn 
                  v-if="!apprMode"
                  ref="appr-btn"
                  name="internal-action-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="approvalAction"
                  @callbackApprAction="approvalActionCallback"
                  @requestAfterAction="research"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  :disabled="disabled"
                  label="실시계획서명"
                  name="actionName"
                  v-model="action.actionName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                      <c-datepicker
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  :range="true"
                  label="기간"
                  name="period"
                  v-model="action.period"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <c-field
                  :disabled="disabled"
                  :editable="editable"
                  label="검토자"
                  name="reviewUserId"
                  v-model="action.reviewUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <c-field
                  :disabled="disabled"
                  :editable="editable"
                  label="승인자"
                  name="approvalUserId"
                  v-model="action.approvalUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <c-plant
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  name="plantCd"
                  v-model="action.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="SAI_INTERNAL_ACTION_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="saiInternalActionTypeCd"
                  label="심사구분"
                  v-model="action.saiInternalActionTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="3"
                  label="심사 목적 및 범위"
                  name="auditPurposeScope"
                  v-model="action.auditPurposeScope">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="3"
                  label="심사 준비 요청사항"
                  name="auditPreRequire"
                  v-model="action.auditPreRequire">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-table
      ref="actionTable"
      title="수행계획 목록"
      :columns="grid.columns"
      :data="action.actionPlans"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="saiInternalActionPlanId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled&&action.actionPlans.length>0" label="피심사팀 제외" icon="remove" @btnClicked="deletePlan" />
          <c-btn v-if="editable&&!disabled" label="피심사팀 추가" icon="add" @btnClicked="addPlan" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'userIds'">
          <c-tag 
            :editable="editable"
            icon="person_outline"
            itemText="userName"
            itemValue="check"
            name="userIds" 
            :isAdd="!disabled"
            :disabled="disabled"
            v-model="props.row.userIds"
            @addTag="addManager(props.row)"
            @removeTag="item => removeManager(props.row, item)"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
// import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'internal-action-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiInternalActionId: '',
      }),
    },
    action: {
      type: Object,
      default: () => ({
        saiInternalActionId: '',  // 내부심사 수행계획서 일련번호
        plantCd: '',  // 사업장코드
        actionName: '',  // 실시계획서명
        actionStartDate: '',  // 심사시작일
        actionEndDate: '',  // 심사종료일
        period: [],
        saiInternalActionStepCd: '',  // 내부심사 수행계획서 진행상태
        saiInternalActionTypeCd: null,  // 심사구분
        auditPurposeScope: '',  // 심사 목적 및 범위
        auditPreRequire: '',  // 심사 준비 요청사항
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        reviewUserId: '', // 검토자
        approvalUserId: '', // 승인자
        regDtStr: '', 
        regUserName: '',  // 등록자
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        actionPlans: [], // 실시계획
        deleteActionPlans: [], // 실시계획 [삭제]
        targetDepts: [], // 피심사팀
        deleteTargetDepts: [], // 피심사팀 [삭제]
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
    apprMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '피심사팀',
            deptCd: 'deptCd',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            setHeader: true,
            name: 'dates',
            field: 'dates',
            label: '기간',
            align: 'center',
            style: 'width:200px',
            sortable: false,
            type: 'date',
            range: true,
            required: true,
          },
          {
            name: 'managerUserName',
            field: 'managerUserName',
            label: '심사팀장',
            style: 'width:140px',
            align: 'center',
            type: 'user',
            userId: 'managerUserId',
            deptCd: 'managerDeptCd',
            deptName: 'managerDeptName',
            sortable: false,
          },
          {
            name: 'userIds',
            field: 'userIds',
            label: '심사자',
            align: 'left',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'auditContents',
            field: 'auditContents',
            label: '심사내용',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'textarea',
          },
          // {
          //   name: 'remark',
          //   field: 'remark',
          //   label: '비고',
          //   align: 'left',
          //   style: 'width:150px',
          //   sortable: true,
          //   type: 'textarea'
          // },
        ],
        data: [],
      },
      gridTeam: {
        columns: [],
        height: '275px',
      },
      editable: true,
      mappingType: 'PUT',
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSave: false,
      isApproval: false,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    reg() {
      return this.action.regUserName + ' / ' + this.action.regDtStr
    },
    gridHeight() {
      return String(this.contentHeight - 400) + 'px';
    },
    isOld() {
      return Boolean(this.popupParam.saiInternalActionId);
    },
    apprEditable() {
      return this.isOld
        && this.action.saiInternalActionStepCd === 'IA00000001'
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.action.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.action.approvalStatusCd, 
        apprEditable: this.apprEditable,
        param: this.action, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000011', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          saiInternalActionId: this.action.saiInternalActionId,
          isApprContent: true
        },
        approvalRequestName: this.action.actionName, // 결재요청명 (문서 title)
        approvalConnId: this.action.saiInternalActionId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sai.internal.action.insert.url;
      this.deleteUrl = transactionConfig.sai.internal.action.delete.url;
      this.approvalUrl = transactionConfig.sai.internal.action.update.url;
      // list setting
    },
    research() {
      this.$emit('getDetail')
    },
    deleteAction() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiInternalActionId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    setSaveValue() {
      return new Promise(resolve => {
        this.action.regUserId = this.$store.getters.user.userId
        this.action.chgUserId = this.$store.getters.user.userId
        
        this.action.actionStartDate = this.action.period[0]
        this.action.actionEndDate = this.action.period[1]

        /**
         * 1. 실시계획
         * 2. 피심사팀 : 실시계획 추가될때마다 setting 해도 됨
         */
        // 피심사팀
        if (this.action.actionPlans && this.action.actionPlans.length > 1) {
          this.$_.forEach(this.action.actionPlans, plan => {
            plan.startDate = plan.dates[0]
            plan.endDate = plan.dates[1]
          })
        }

        resolve(true);
      });
    },
    saveValue() {
      return new Promise(resolve => {
        this.isSave = !this.isSave
        resolve(true);
      });
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    saveAction() {
      if (this.popupParam.saiInternalActionId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sai.internal.action.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sai.internal.action.insert.url;
      }
      
      if (this.action.actionPlans && this.action.actionPlans.length > 1) {
        this.$_.forEach(this.action.actionPlans, plan => {
          plan.startDate = plan.dates[0]
          plan.endDate = plan.dates[1]
        })
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: '저장하시겠습니까?', 
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.saveValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveActionCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'saiInternalActionId', _result.data)
      this.$emit('getDetail');
    },
    approvalAction() {
      /**
       * 수행계획 목록
       *  - 데이터가 하나 이상 있는지?
       *  - 필수값 체크 
       */
      let _check = true;
      // 심사자
      this.$_.forEach(this.action.actionPlans, item => {
        if (item.userIds.length == 0) {
          _check = false;
        }
      });
      if (!_check) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '피심사팀별 심사자를 추가하시기 바랍니다.', 
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      let _checkchklist = true;
      // 체크리스트
      this.$_.forEach(this.action.targetDepts, item => {
        if (item.checklist.length == 0) {
          _checkchklist = false;
        }
      });
      if (!_checkchklist) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '피심사팀별 체크리스트를 추가하시기 바랍니다.', 
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      // 수행계획
      if (this.$comm.validTable(this.grid.columns, this.action.actionPlans)) {
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인', 
              message: '결재요청 하기 전 입력된 값을 저장합니다. 진행하시겠습니까?',
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                let promises = [
                  {
                    func: this.setSaveValue,
                  },
                  {
                    func: this.approvalValue
                  },
                ];
                this.$comm.orderedPromise(promises);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }

    },
    approvalActionCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    addPlan() {
      // 연간 정보를 따로 가져오게는 하지 않음.
      // 자유롭게 선택하게 하고 이후 계획 대비 실적을 보여줄때 표시하게 처리
      this.popupOptions.title = '부서조회'; 
      this.popupOptions.param = {
        plantCd: this.action.plantCd,
      }
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closeDept;
    },
    closeDept(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          // 수행계획 
          if (this.$_.findIndex(this.action.actionPlans, { deptCd: item.deptCd }) === -1) {
            this.action.actionPlans.splice(0, 0, {
              saiInternalActionPlanId: uid(),  // 내부심사 수행계획서 실시계획 일련번호
              saiInternalActionId: this.popupParam.saiInternalActionId,  // 내부심사 수행계획서 일련번호
              deptCd: item.deptCd,
              deptName: item.deptName,
              startDate: '',  // 시작일
              endDate: '',  // 종료일
              dates: [],  // 기간
              userIds: [],
              auditContents: '',  // 내부심사내용
              remark: '',  // 비고
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }

          // 피심사팀
          if (this.$_.findIndex(this.action.targetDepts, { deptCd: item.deptCd }) === -1) {
            this.action.targetDepts.push({
              saiInternalActionTargetDeptId: uid(),  // 내부심사 피심사팀 일련번호
              saiInternalActionId: this.popupParam.saiInternalActionId,  // 내부심사 수행계획서 일련번호
              deptCd: item.deptCd,  // 피심사부서코드
              deptName: item.deptName,  // 피심사부서
              saiInternalActionTargetDeptStepCd: 'IATD000001',  // 내부심사 피심사팀 진행상태
              managerUserId: '',
              userIds: [],
              resultRemark: '',  // 결과요약
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        });
      }
    },
    deletePlan() {
      let selectData = this.$refs['actionTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '제외할 행을 지정하세요.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '피심사팀별 체크리스트 정보 또한 같이 제외됩니다. 제외하시겠습니까?',  
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, item => {
              // 수행계획
              if (!this.action.deleteActionPlans) {
                this.action.deleteActionPlans = [];
              }
              if (item.editFlag !== 'C' && this.$_.findIndex(this.action.deleteActionPlans, { saiInternalActionPlanId: item.saiInternalActionPlanId }) === -1) {
                this.action.deleteActionPlans.push(item)
              }
              this.action.actionPlans = this.$_.reject(this.action.actionPlans, item);

              // 피심사팀
              if (!this.action.deleteTargetDepts) {
                this.action.deleteTargetDepts = [];
              }
              /**
               * 아직 해당 부서가 수행계획에 존재할시에는 진행 X
               */
              let isExists = this.$_.findIndex(this.action.actionPlans, { deptCd: item.deptCd }) > -1
              if (!isExists) {
                if (item.editFlag !== 'C' && this.$_.findIndex(this.action.deleteTargetDepts, { deptCd: item.deptCd }) === -1) {
                  let deleteData = this.$_.find(this.action.targetDepts, { deptCd: item.deptCd });
                  this.action.deleteTargetDepts.push({
                    saiInternalActionTargetDeptId: deleteData.saiInternalActionTargetDeptId
                  })
                }
                this.action.targetDepts = this.$_.reject(this.action.targetDepts, { deptCd: item.deptCd });
              }
            });
            this.$refs['actionTable'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addManager(row) {
      this.row = row;
      this.popupOptions.title = '사용자 검색'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      // let cntdupl = 0;
      // let cntdupl2 = 0;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.row.userIds, { userId: item.userId }) === -1 && this.$_.findIndex(this.row.userIds, { teamDeptCd: item.deptCd }) === -1 && this.row.deptCd != item.deptCd) {
            this.row.userIds.push(
              {
                userId: item.userId,
                userName: item.userName + '/' + item.deptName,
                deptCd: this.row.deptCd,
                teamDeptCd: item.deptCd,
              }
            )
          }
          // if (this.$_.findIndex(this.row.userIds, { teamDeptCd: item.deptCd }) > -1) {
          //   cntdupl = cntdupl + 1;
          // }
          // if (this.row.deptCd == item.deptCd) {
          //   cntdupl2 = cntdupl2 + 1;
          // }
        })
        if (this.row.editFlag !== 'C') {
          this.$set(this.row, 'editFlag', 'U')
        }
        // if (cntdupl > 0) {
        //   window.getApp.$emit('ALERT', {
        //     title: '안내',
        //     message: '피심사팀별 심사자는 부서별 1인만 가능합니다(다른 부서 가능).', // 심사팀에 팀장을 추가하시기 바랍니다.
        //     type: 'warning', // success / info / warning / error
        //   });
        // }
        // if (cntdupl2 > 0) {
        //   window.getApp.$emit('ALERT', {
        //     title: '안내',
        //     message: '심사자는 피심사팀 소속이 될 수 없습니다.', // 심사팀에 팀장을 추가하시기 바랍니다.
        //     type: 'warning', // success / info / warning / error
        //   });
        // }
      }
    },
    removeManager(row, item) {
      row.userIds = this.$_.reject(row.userIds, item)
      if (row.editFlag !== 'C') {
        this.$set(row, 'editFlag', 'U')
      }
    }
  }
};
</script>